import { useEffect, useContext, useState } from "react";
import { FormBuilderContext } from "@context/formbuilder.context";

export default function Input({ hideLables, elem, idx }) {
  const {
    inputState,
    updateState,
    editing,
    errors,
    hasError,
    setInputState,
    validate,
    resetFields,
    sendResponse,
  } = useContext(FormBuilderContext);
  const [elemState, setElemState] = useState(inputState[idx][elem.id]);

  const elemChange = (e, elem, idx) => {
    const { value, id, name } = e.target;
    let _value = value;
    if (elem.format) {
      _value = elem.format(value);
    }
    const _data = { ...inputState[idx], [name || id]: _value };
    inputState[idx] = _data;
    setInputState([...inputState]);
    setElemState(_value);

    if (elem.validation) {
      if (errors.list && errors.list[idx] && errors.list[idx].length > 0) {
        validate(e, elem, idx);
      }
    } else {
      sendResponse(e, idx);
    }
  };

  useEffect(() => {
    if (resetFields || updateState) {
      setElemState(inputState[idx][elem.id]);
    }
  }, [resetFields, updateState, inputState]);

  return (
    <>
      {!editing && elem.type !== "hidden" && elem.value && (
        <>
          {!hideLables && <strong>{elem.label}</strong>}
          <span>{elem.altValue || elem.value}</span>
        </>
      )}
      {editing && (
        <>
          <label htmlFor={elem.id} className={hideLables ? "hide" : ""}>
            <strong>
              {elem.label}
              {elem.subLabel && (
                <>
                  <br />
                  <span
                    dangerouslySetInnerHTML={{ __html: elem.subLabel }}
                  ></span>
                </>
              )}
            </strong>
          </label>
          <input
            type={elem.type}
            id={elem.id}
            name={elem.id}
            value={elemState || ""}
            placeholder={`${elem.placeholder} ${elem.required ? "*" : ""}`}
            className={`${hasError(idx, elem.id) ? "error" : ""} ${
              elem.class ? elem.class : ""
            } ${elem.required ? "required" : ""}`}
            onChange={(e) => elemChange(e, elem, idx)}
            onBlur={(e) => validate(e, elem, idx)}
            autoFocus={elem.autofocus}
            required={elem.required}
            disabled={elem.disabled}
            min={elem.min}
            max={elem.max}
            maxLength={elem.maxlength}
            {...elem.data_tag}
          />
        </>
      )}
    </>
  );
}
