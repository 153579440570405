import { useEffect, useContext, useState } from "react";
import { FormBuilderContext } from "@context/formbuilder.context";

export default function Radio({ hideLables, elem, idx }) {
  const {
    inputState,
    updateState,
    editing,
    setInputState,
    validate,
    resetFields,
  } = useContext(FormBuilderContext);
  const [elemState, setElemState] = useState(inputState[idx][elem.name]);
  const elemChange = (e) => {
    const { value, name } = e.target;
    const _data = { ...inputState[idx], [name]: value };
    inputState[idx] = _data;
    setInputState([...inputState]);
    validate(e, elem, idx);
    setElemState(inputState[idx][elem.name]);
  };

  useEffect(() => {
    if (resetFields || updateState) {
      setElemState(inputState[idx][elem.name]);
    }
  }, [resetFields, updateState, inputState]);

  return (
    <>
      {!editing && elem.checked && <span>{elem.label}</span>}
      {editing && (
        <>
          <label htmlFor={elem.id}>
            <div
              className={`radio-btn ${
                inputState[idx][elem.name] === elem.value && "checked"
              }`}
            >
              <i className="cat_paw"></i>
            </div>
            <strong>
              {elem.label}
              {elem.subLabel && (
                <>
                  <br />
                  <span
                    dangerouslySetInnerHTML={{ __html: elem.subLabel }}
                  ></span>
                </>
              )}
            </strong>
          </label>
          <input
            type={elem.type}
            id={elem.id}
            name={elem.name}
            value={elem.value}
            checked={elemState === elem.value}
            placeholder={elem.placeholder}
            className={elem.class}
            onClick={(e) => elemChange(e)}
            onChange={(e) => elemChange(e)}
          />
        </>
      )}
    </>
  );
}
