export const buildStates = (form) => {
  let _groups = [];
  if (form && form.formGroup) {
    form.formGroup.forEach((grp) => {
      let _states = {};
      grp.elements.forEach((el) => {
        if (el.type === "checkbox") {
          _states = { ..._states, [el.id]: el.value };
        } else if (
          (el.type !== "radio" && el.type !== "creditexp") ||
          (el.type === "radio" && el.checked)
        ) {
          _states = { ..._states, [el.name || el.id]: el.value };
        } else if (el.type === "creditexp") {
          _states = {
            ..._states,
            [el.id]: el.value,
            [el.id2]: el.altValue,
            [el.id3]: el.cvvValue,
          };
        }
      });
      _groups.push(_states);
    });
  }
  return _groups;
};
