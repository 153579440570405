import { tryPostApi } from "@lib/fetch-helpers";
import { phone } from "phone";

const emailSuffixes = [
  "com",
  "org",
  "net",
  "edu",
  "io",
  "mil",
  "us",
  "uk",
  "in",
];
const emailDomains = ["gmail", "yahoo", "hotmail"];

const computeLevenshteinDistance = (givenWord: string, desiredWord: string) => {
  const distanceArray = [];

  for (let i = 0; i <= desiredWord.length; i++) {
    distanceArray[i] = [i];
    for (let j = 1; j <= givenWord.length; j++) {
      distanceArray[i][j] =
        i === 0
          ? j
          : Math.min(
              distanceArray[i - 1][j] + 1,
              distanceArray[i][j - 1] + 1,
              distanceArray[i - 1][j - 1] +
                (givenWord[j - 1] === desiredWord[i - 1] ? 0 : 1)
            );
    }
  }
  return distanceArray[desiredWord.length][givenWord.length];
};

export const checkEmailInUse = async (email: string, customerDetails: any) => {
  if (
    customerDetails &&
    customerDetails.shopify &&
    customerDetails.shopify.email === email
  ) {
    return false;
  } else {
    let emailIsInUse = await tryPostApi("/api/store/findCustomer", {
      options: `email:'${email}'`,
    });
    if (emailIsInUse && emailIsInUse.length > 0) {
      return "This Email Address Is In Use";
    }
  }
};

export const validateEmail = (email: string, label: string) => {
  let errorResp: any = false;
  if (
    email &&
    email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    const emailSplitByPeriod = email.split(".");
    const emailSuffix = emailSplitByPeriod[emailSplitByPeriod.length - 1];
    if (!emailSuffixes.includes(emailSuffix)) {
      const possibleAlternativeSuffix = emailSuffixes
        .map((suffix) => {
          return {
            suffix,
            letterDifference: computeLevenshteinDistance(emailSuffix, suffix),
          };
        })
        .sort((a, b) => a.letterDifference - b.letterDifference)[0];
      if (
        !(
          possibleAlternativeSuffix.letterDifference /
            possibleAlternativeSuffix.suffix.length >=
          0.6
        )
      ) {
        if (errorResp) {
          errorResp += `.${possibleAlternativeSuffix.suffix}?`;
        } else {
          errorResp = `Your Email is Invalid - Did You Mean ${possibleAlternativeSuffix.suffix}?`;
        }
      }
    }
  } else if (email !== "") {
    errorResp = "Invalid Email Address";
  }
  return errorResp;
};

export const validatePhoneNumber = async (
  phoneNumber: string,
  customerDetails: any
) => {
  const phoneNumberDetails = phone(phoneNumber, { country: "USA" });
  if (
    customerDetails &&
    customerDetails.shopify &&
    customerDetails.shopify.phone &&
    customerDetails.shopify.phone === phoneNumberDetails.phoneNumber
  ) {
    return false;
  } else {
    let phoneIsInUse = await tryPostApi("/api/store/findCustomer", {
      options: `phone:'${phoneNumberDetails.phoneNumber}'`,
    });

    if (phoneIsInUse && phoneIsInUse.length > 0) {
      return "Phone Number Is In Use";
    }
  }
};

export const checkPhoneNumber = (inputString: string, label: string) => {
  if (inputString === "" || inputString === null || inputString === undefined) {
    return false;
  }

  const phoneNumberDetails = phone(inputString, { country: "USA" });

  if (!phoneNumberDetails.isValid) {
    return "Invalid Phone Number";
  }

  return false;
};

export const checkZip = (inputString: string) => {
  const _valid = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(inputString);
  if (_valid) {
    return false;
  }
  return "Invalid Zip Code";
};

export const required = (inputString: string, label: string) => {
  /*
  if (
    /^[a-zA-Z0-9- ,_.@+!#()]*$/.test(inputString) === false ||
    inputString[0] === " "
  ) {
    return `${label} can not have spaces before or special characters`;
  }
  */
  if (inputString && inputString.length >= 1 && inputString !== " ") {
    return false;
  }
  return `${label} is Required`;
};

export const minLength = (inputString: string, label: string, d: number) => {
  if (inputString.length < d) {
    return `${label} is not valid`;
  }
  return false;
};

export const validCardNumber = (inputString: string) => {
  if (inputString) {
    const _val = inputString.replace(/-/g, "");
    const regex = new RegExp("^[0-9]{13,19}$");
    if (!regex.test(_val)) {
      return "Not a valid credit card number";
    }
    if (!checkCC(_val)) {
      return "Not a valid credit card number";
    }
    return false;
  } else {
    return "Credit card number required";
  }
};

const checkCC = (val: any) => {
  let validsum = 0;
  let k = 1;
  for (let l = val.length - 1; l >= 0; l--) {
    let calck = 0;
    calck = Number(val.charAt(l)) * k;
    if (calck > 9) {
      validsum = validsum + 1;
      calck = calck - 10;
    }
    validsum = validsum + calck;
    if (k == 1) {
      k = 2;
    } else {
      k = 1;
    }
  }
  return validsum % 10 == 0;
};

export const checkState = (inputString: string) => {
  if (inputString) {
    const state = inputString.toUpperCase();
    if (state.match(/APO|FPO|DPO/g)) {
      return "We currently do not ship to APO/DPO/FPO address's";
    }

    if (state.match(/AK|HI|PR/g)) {
      return "We currently only ship to the continental United States";
    }
    if (inputString.length >= 2) {
      return false;
    }
    return "State is Required";
  }
  return "State is Required";
};
