import { useContext } from "react";
import { FormBuilderContext } from "@context/formbuilder.context";
import Input from "./input.component";
import Smarty from "./smarty.component";
import AutoResponse from "./autoresponse.component";
import Radio from "./radio.component";
import Checkbox from "./checkbox.component";
import Select from "./select.component";
import CCexp from "./creditcard.component";

export default function Form({ children }) {
  const {
    formData,
    editing,
    errors,
    hideLables,
    collapse,
    styles,
    warningList,
  } = useContext(FormBuilderContext);
  const selectTypes = [
    "select",
    "radio",
    "creditexp",
    "smarty",
    "checkbox",
    "auto",
    "CTA",
  ];
  const hide_lables = hideLables || formData.hideLables;
  return (
    <form
      className={`form-builder ${styles} ${editing ? "active" : "notActive"}`}
      id={formData.id || ""}
    >
      {formData.formGroup &&
        formData.formGroup.map((grp, idx) => (
          <fieldset disabled={!editing} className={formData.style} key={idx}>
            {grp.title && (
              <legend onClick={collapse}>
                <span>{grp.title}</span>
              </legend>
            )}
            <ul className={!editing ? grp.style : grp.formStyle}>
              {grp.elements.map((el, cdx) => (
                <li key={cdx} className={el.style}>
                  {!selectTypes.includes(el.type) && (
                    <Input hideLables={hide_lables} elem={el} idx={idx} />
                  )}
                  {el.type === "smarty" && (
                    <Smarty hideLables={hide_lables} elem={el} idx={idx} />
                  )}
                  {el.type === "auto" && (
                    <AutoResponse
                      hideLables={hide_lables}
                      elem={el}
                      idx={idx}
                    />
                  )}
                  {el.type === "radio" && (
                    <Radio hideLables={hide_lables} elem={el} idx={idx} />
                  )}
                  {el.type === "checkbox" && (
                    <Checkbox hideLables={hide_lables} elem={el} idx={idx} />
                  )}
                  {el.type === "select" && (
                    <Select hideLables={hide_lables} elem={el} idx={idx} />
                  )}
                  {el.type === "creditexp" && (
                    <CCexp hideLables={hide_lables} elem={el} idx={idx} />
                  )}
                  {el.cta && <div className={el.ctaClass}>{el.cta}</div>}
                </li>
              ))}
              {editing && grp.cta && (
                <li className={grp.ctaClass}>{grp.cta}</li>
              )}
            </ul>
            {errors.list &&
              errors.list[idx] != undefined &&
              errors.list[idx].length > 0 && (
                <div className="error">
                  <ul>
                    {errors.list[idx].map((err, edx) => (
                      <li key={edx}>Error: {err.value}</li>
                    ))}
                  </ul>
                </div>
              )}
            {warningList && warningList.componentIndex === idx && (
              <div className="error center">{warningList.warningText}</div>
            )}
          </fieldset>
        ))}
      {children}
    </form>
  );
}
