import { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function Modal({
  active = false,
  action = {},
  type = "",
  title = "",
  description = "",
  style = false,
  children,
  customClass = "",
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [modalInnerStyle, setModalInnerStyle] = useState(false);
  const [modalOuterStyle, setModalOuterStyle] = useState(false);
  const [modalCloseIconStyle, setModalCloseIconStyle] = useState(customClass);

  useEffect(() => {
    if (style) {
      setModalInnerStyle(
        style?.modalInner !== undefined ? style.modalInner : false
      );
      setModalOuterStyle(
        style?.modalOuter !== undefined ? style.modalOuter : false
      );
    }
  }, [style]);

  useEffect(() => {
    setIsOpen(active);
  }, [active]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        onClose={() => action && action(false)}
        className="cover"
      >
        <Transition.Child
          show={isOpen.toString()}
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-linear duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fuzzy" />
        </Transition.Child>

        <Transition.Child
          show={isOpen.toString()}
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-linear duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="dialog-wrapper">
            <Dialog.Panel className={`${modalOuterStyle || ""} dialog-panel`}>
              {action && (
                <button
                  className={`${
                    modalCloseIconStyle !== false
                      ? modalCloseIconStyle
                      : "close-icon"
                  } corner`}
                  onClick={() => action(false)}
                ></button>
              )}
              {title && (
                <Dialog.Title className={type}>
                  {type === "alert" && (
                    <i className="far fa-exclamation-circle"></i>
                  )}
                  {type === "question" && (
                    <i className="far fa-exclamation-circle"></i>
                  )}
                  {type === "success" && (
                    <i className="far fa-check-circle"></i>
                  )}
                  <span>{title}</span>
                </Dialog.Title>
              )}
              {description && (
                <Dialog.Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
              <div className={`${modalInnerStyle || ""} dialog-content`}>
                {children}
              </div>
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}
