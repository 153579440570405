import { useEffect, useContext, useState } from "react";
import { FormBuilderContext } from "@context/formbuilder.context";

export default function Select({ hideLables, elem, idx }) {
  const {
    inputState,
    updateState,
    editing,
    setInputState,
    validate,
    resetFields,
  } = useContext(FormBuilderContext);
  const [elemState, setElemState] = useState(inputState[idx][elem.id]);

  const elemChange = (e) => {
    const { value, id } = e.target;
    const _data = { ...inputState[idx], [id]: value };
    inputState[idx] = _data;
    // setInputState([...inputState]);
    validate(e, elem, idx);
    setElemState(value);
  };

  useEffect(() => {
    if (resetFields || updateState) {
      setElemState(inputState[idx][elem.id]);
    }
  }, [resetFields, updateState, inputState]);

  return (
    <>
      {!editing && elem.value && (
        <>
          {!hideLables && <strong>{elem.label}</strong>}
          <span>{elem.altValue || elem.value}</span>
        </>
      )}
      {editing && (
        <>
          <label htmlFor={elem.id} className={hideLables ? "hide" : ""}>
            <strong>
              {elem.label}
              {elem.subLabel && (
                <>
                  <br />
                  <span
                    dangerouslySetInnerHTML={{ __html: elem.subLabel }}
                  ></span>
                </>
              )}
            </strong>
            {elem.labelCta && (
              <span className={elem.labelCtaClass}>{elem.labelCta}</span>
            )}
          </label>
          <select
            id={elem.id}
            name={elem.name}
            value={elemState}
            onChange={(e) => elemChange(e)}
          >
            {elem.options.map((opt) => (
              <option value={opt.value} key={opt.value}>
                {opt.name}
              </option>
            ))}
          </select>
        </>
      )}
    </>
  );
}
