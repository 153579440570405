import { useEffect, useContext, useState } from "react";
import { FormBuilderContext } from "@context/formbuilder.context";
import { smartyAutoComplete } from "./lib/smartyStreets";

const formatAutoResponse = (addr) => {
  return `${addr.street1}, ${addr.city} ${addr.region} ${addr.postalCode}`;
};

export default function Smarty({ hideLables, elem, idx }) {
  const {
    inputState,
    updateState,
    editing,
    hasError,
    setInputState,
    sendResponse,
    resetFields,
    setResetFields,
    setWarningList,
    validate,
  } = useContext(FormBuilderContext);
  const [elemState, setElemState] = useState(inputState[idx][elem.id]);
  const [smartyResp, setSmartyResp] = useState(false);

  const callSmarty = async (input) => {
    const suggested = await smartyAutoComplete(input);
    if (suggested && suggested.length >= 1) {
      setSmartyResp(suggested);
    }
  };

  const useAddress = (e, n) => {
    e.preventDefault();
    const { street2, ...noStreetTwoSmarty } = smartyResp[n];
    const _data = { ...inputState[idx], ...noStreetTwoSmarty };
    inputState[idx] = _data;
    setInputState([...inputState]);
    if (_data.street2 === "" && street2 !== "") {
      setWarningList({
        componentIndex: idx,
        warningText: "Does this address have an apartment/suite number? ",
      });
    } else {
      setWarningList(false);
    }
    sendResponse(e, idx);
    setSmartyResp(false);
    setResetFields(true);
  };

  const elemChange = (e) => {
    const { value, id, name } = e.target;
    let _value = value;
    if (elem.format) {
      _value = elem.format(value);
    }
    const _data = { ...inputState[idx], [name || id]: _value };
    inputState[idx] = _data;
    setInputState([...inputState]);
    setElemState(_value);
    if (_value.length >= 2) {
      callSmarty(_value);
    }
  };

  useEffect(() => {
    if (resetFields || updateState) {
      setElemState(inputState[idx][elem.id]);
    }
  }, [resetFields, updateState, inputState]);

  return (
    <>
      {!editing && elem.type !== "hidden" && elem.value && (
        <>
          {!hideLables && <strong>{elem.label}</strong>}
          <span>{elem.altValue || elem.value}</span>
        </>
      )}
      {editing && (
        <>
          <label htmlFor={elem.id} className={hideLables ? "hide" : ""}>
            <strong>
              {elem.label}
              {elem.subLabel && (
                <>
                  <br />
                  <span
                    dangerouslySetInnerHTML={{ __html: elem.subLabel }}
                  ></span>
                </>
              )}
            </strong>
          </label>
          <input
            type="text"
            id={elem.id}
            name={elem.id}
            value={elemState || ""}
            placeholder={`${elem.placeholder} ${elem.required ? "*" : ""}`}
            className={`${hasError(idx, elem.id) ? "error" : ""} ${
              elem.class ? elem.class : ""
            } ${elem.required ? "required" : ""}`}
            onChange={(e) => elemChange(e)}
            onBlur={(e) => validate(e, elem, idx)}
            autoFocus={elem.autofocus}
            required={elem.required}
            disabled={elem.disabled}
            maxLength={elem.maxlength}
            autoComplete="off"
          />

          <div
            id="smartyStreetDropdown"
            className={!smartyResp ? "hidden" : "smartyResponse"}
          >
            {smartyResp &&
              smartyResp.map((addr, idx) => (
                <button
                  onClick={(e) => {
                    useAddress(e, idx);
                  }}
                  key={idx}
                >
                  {formatAutoResponse(addr)}
                </button>
              ))}
          </div>
        </>
      )}
    </>
  );
}
