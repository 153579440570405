import * as SmartyStreetsSDK from "smartystreets-javascript-sdk";
import { useState, useEffect } from "react";
import Modal from "@directives/modal.directive";
import Button from "@components/elements/button.component";

const smartyStreetsAPIKey = process.env.NEXT_PUBLIC_SS_KEY;
const AutoCompleteLookup = SmartyStreetsSDK.usAutocompletePro.Lookup;
const smartyStreetsCredentials = new SmartyStreetsSDK.core.SharedCredentials(
  smartyStreetsAPIKey
);
const smartyStreetsClientBuilder = new SmartyStreetsSDK.core.ClientBuilder(
  smartyStreetsCredentials
).withLicenses(["us-autocomplete-pro-cloud"]);
const AutoCompleteClient =
  smartyStreetsClientBuilder.buildUsAutocompleteProClient();

export const smartyAutoComplete = async (input) => {
  let lookupObject = new AutoCompleteLookup(input);
  lookupObject.excludeStates = ["HI", "AK", "PR", "AA", "AE", "AP"];
  lookupObject.maxResults = 5;
  lookupObject.source = "all";
  try {
    const autoCompleteResults = await AutoCompleteClient.send(lookupObject);
    if (autoCompleteResults && autoCompleteResults.result.length >= 1) {
      const similarAddresses = autoCompleteResults.result.map((obj) => {
        return {
          street1: obj.streetLine,
          street2: obj.secondary,
          city: obj.city,
          region: obj.state,
          postalCode: obj.zipcode,
        };
      });
      return similarAddresses;
    }
    return false;
  } catch (err) {
    console.error(`Smarty Autocomplete Error:`, err);
  }
};

const checkAddressUpdates = (
  updateFootnotes,
  updatedComponents,
  originalAddress
) => {
  while (updateFootnotes && updateFootnotes !== "") {
    let currentUpdate = updateFootnotes.slice(0, 2);
    if (currentUpdate === "LL" || currentUpdate === "LI") {
      currentUpdate = updateFootnotes.slice(0, 3);
      updateFootnotes = updateFootnotes.substring(3);
    } else {
      updateFootnotes = updateFootnotes.substring(2);
    }

    if (currentUpdate === "A#") {
      originalAddress.postalCode = updatedComponents.zipCode;
    } else if (
      currentUpdate === "B#" ||
      currentUpdate === "U#" ||
      currentUpdate === "V#"
    ) {
      originalAddress.city = updatedComponents.cityName;
    } else if (currentUpdate === "C#") {
      originalAddress.city = updatedComponents.cityName;
      originalAddress.postalCode = updatedComponents.zipCode;
    } else if (
      currentUpdate === "M#" ||
      currentUpdate === "N#" ||
      currentUpdate === "P#" ||
      currentUpdate === "L#"
    ) {
      originalAddress.street1 = createStreet1(updatedComponents);
      originalAddress.street2 = createStreet2(updatedComponents);
    } else if (
      currentUpdate === "F#" ||
      currentUpdate === "R#" ||
      currentUpdate === "I#" ||
      currentUpdate === "D#" ||
      currentUpdate === "H#"
    ) {
      return false;
    }
  }
  return originalAddress;
};

export const validateAddress = async (address) => {
  const validationClient = new SmartyStreetsSDK.core.buildClient.usStreet(
    smartyStreetsCredentials
  );
  const { Lookup } = SmartyStreetsSDK.usStreet;
  const validationLookup = new Lookup();

  // Populate Lookup
  validationLookup.addressee = `${address.firstName} ${address.lastName}`;
  validationLookup.street = address.street1;
  validationLookup.street2 = address.street2;
  validationLookup.city = address.city;
  validationLookup.state = address.region;
  validationLookup.zipCode = address.postalCode;
  validationLookup.maxCandidates = 1;

  // Try / Catch for Validation Request
  try {
    await validationClient.send(validationLookup);
    if (validationLookup.result.length === 1) {
      if (
        validationLookup.result[0].analysis.dpvMatchCode === "Y" ||
        validationLookup.result[0].analysis.dpvMatchCode === "S" ||
        validationLookup.result[0].analysis.dpvMatchCode === "D"
      ) {
        return checkAddressUpdates(
          validationLookup.result[0].analysis.footnotes,
          validationLookup.result[0].components,
          address
        );
      } else {
        return false;
      }
    }
  } catch (err) {
    console.error(`There was an error validating the address`, err);
  }
};

const createStreet1 = (updatedAddress) => {
  return `${updatedAddress.primaryNumber} ${
    updatedAddress.streetPredirection ?? ""
  }${updatedAddress.streetName} ${updatedAddress.streetSuffix} ${
    updatedAddress.streetPostdirection ?? ""
  }`
    .replaceAll("  ", " ")
    .trim();
};

const createStreet2 = (updatedAddress) => {
  return `${updatedAddress.secondaryDesignator ?? ""} ${
    updatedAddress.secondaryNumber ?? ""
  } ${updatedAddress.extraSecondaryDesignator ?? ""} ${
    updatedAddress.extraSecondaryNumber ?? ""
  }`
    .replaceAll("  ", " ")
    .trim();
};

export function SmartyStreetModal({ address, openModel, useAddress }) {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(openModel);
  }, [openModel]);

  return (
    <>
      <Modal
        active={modalOpen}
        action={false}
        title="Shipping Address"
        description="We’re unable to verify your address."
        type="alert"
        style=""
      >
        {address && (
          <div className="smarty flex-wrap">
            <div>
              <p>
                {address[0].firstName} {address[0].lastName}
              </p>
              <p>{address[0].street1}</p>
              {address[0].street2 && <p>{address[0].street2}</p>}
              <p>
                {address[0].city} {address[0].region} {address[0].postalCode}
              </p>
              <Button
                handleClick={() => {
                  useAddress(address[0]);
                  setModalOpen(false);
                }}
                text="Use Suggested Address"
              />
            </div>
            <div>
              <p>
                {address[1].firstName} {address[1].lastName}
              </p>
              <p>{address[1].street1}</p>
              {address[1].street2 && <p>{address[1].street2}</p>}
              <p>
                {address[1].city} {address[1].region} {address[1].postalCode}
              </p>
              <Button
                handleClick={() => {
                  useAddress(address[1]);
                  setModalOpen(false);
                }}
                text="Use Address I Entered"
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
