import { createContext, useState, useEffect } from "react";
import { buildStates } from "@components/formbuilder/lib/states";
import Form from "@components/formbuilder/form.component";
import { required } from "@lib/validation";
const FormBuilderContext = createContext();

export default function FormBuilderProvider({
  data,
  editing,
  styles = {},
  handleChange = (form) => {},
  reset = undefined,
  updateState = undefined,
  children = undefined,
  hideLables = undefined,
  collapse = undefined,
  getStates = undefined,
  returnState = undefined,
}) {
  const [errors, setErrors] = useState({});
  const [warningList, setWarningList] = useState(false);
  const [inputState, setInputState] = useState(false);
  const [formReady, setFormReady] = useState(false);
  const [formData, setFormData] = useState(false);
  const [resetFields, setResetFields] = useState(false);

  // Form Actions //
  const sendResponse = (e, group, err) => {
    const { value, id, name } = e.target;
    const _data = { ...inputState[group], [name || id]: value };
    let _resp = {
      name: data.name,
      ..._data,
      value: value,
      changedKey: name || id,
      groupIdx: group,
    };
    if (warningList && _resp.changedKey === "street2" && _resp.street2 !== "") {
      setWarningList(false);
    }

    if (err) {
      handleChange({ ..._resp, error: true, errorList: errors.list[group] });
    } else {
      // Check to see if form can be submitted //
      const prevalidate = validateAll();
      handleChange(
        {
          ..._resp,
          error: prevalidate ? true : false,
          errorList: errors.list[group],
        },
        err
      );
      if (returnState) {
        returnState(inputState);
      }
    }
  };

  const hasError = (group, id) => {
    if (
      errors.list &&
      errors.list[group] !== undefined &&
      errors.list[group].length >= 1
    ) {
      const _check = errors.list[group].find((list) => list.eid === id);
      return _check;
    }
    return false;
  };

  const addRemoveError = (group, id, error) => {
    if (error) {
      const _err = { eid: id, value: error };
      if (errors.list[group] === undefined) {
        errors.list[group] = [_err];
      } else {
        const _check = errors.list[group].find((list) => list.eid === id);
        if (!_check) {
          errors.list[group].push(_err);
        }
      }
    } else if (errors.list[group] !== undefined) {
      // const _inx = errors.list[group].indexOf((err) => err.eid === id);
      let _inx = -1;
      for (let i in errors.list[group]) {
        if (errors.list[group][i].eid === id) {
          _inx = i;
          break;
        }
      }
      if (_inx >= 0) {
        errors.list[group].splice(_inx, 1);
      }
    }
    setErrors({ list: errors.list });
  };

  const validateElement = (group, element, value, id, markFields) => {
    let _error = false;
    if (element.validation) {
      _error = element.validation(value, element.label);
    }

    if (!_error && element.required) {
      _error = required(value, element.label);
    }
    if (markFields && _error) {
      addRemoveError(group, id, _error);
    }
    if (!_error) {
      addRemoveError(group, id);
    }

    return _error;
  };

  const validate = (e, element, group) => {
    const { value, id } = e.target;
    const _error = validateElement(group, element, value, id, true);
    if (_error) {
      sendResponse(e, group, true);
    } else {
      sendResponse(e, group);
    }
  };

  const validateAll = (markFields) => {
    if (formData && formData.formGroup) {
      const _errorlist = [];
      formData.formGroup.forEach((group, idx) => {
        group.elements.forEach((element) => {
          if (element.validation) {
            const _error = validateElement(
              idx,
              element,
              inputState[idx][element.id],
              element.id,
              markFields
            );
            if (_error) {
              _errorlist.push(_error);
            }
          }
        });
      });

      return _errorlist.length >= 1
        ? { error: true, errorList: _errorlist }
        : false;
    }
  };

  useEffect(() => {
    if (data || editing || reset) {
      setInputState(buildStates(data));
      if (Array.isArray(data.formGroup)) {
        setErrors({ list: Array(data.formGroup.length) });
      }
      setFormData(data);
      setFormReady(true);
      setResetFields(reset);
    }
  }, [data, editing, reset]);

  useEffect(() => {
    if (!editing) {
      setWarningList(false);
      // setResetFields(reset);
    }
  }, [editing]);

  useEffect(() => {
    if (getStates) {
      const checkform = validateAll(true);
      if (checkform) {
        handleChange(checkform);
      }
      returnState(inputState);
    }
    if (updateState) {
      setInputState(updateState);
    }
  }, [updateState, getStates]);

  return (
    <FormBuilderContext.Provider
      value={{
        formData,
        inputState,
        updateState,
        editing,
        errors,
        resetFields,
        hideLables,
        collapse,
        styles,
        warningList,
        validate,
        setInputState,
        hasError,
        sendResponse,
        addRemoveError,
        setResetFields,
        setWarningList,
      }}
    >
      {formReady && <Form>{children}</Form>}
    </FormBuilderContext.Provider>
  );
}

const FormBuilderConsumer = FormBuilderContext.Consumer;

export { FormBuilderConsumer, FormBuilderContext };
