import { useEffect, useContext, useState } from "react";
import { FormBuilderContext } from "@context/formbuilder.context";
import { tryPostApi } from "@lib/fetch-helpers";
export default function AutoResponse({ hideLables, elem, idx }) {
  const {
    inputState,
    updateState,
    editing,
    hasError,
    setInputState,
    sendResponse,
    resetFields,
  } = useContext(FormBuilderContext);

  const [elemState, setElemState] = useState(inputState[idx][elem.id]);
  const [autoResp, setAutoResp] = useState(false);

  const callEndpoint = async (input) => {
    const suggested = await tryPostApi(elem.endpoint, {
      options: input,
    });
    if (suggested.length >= 1) {
      setAutoResp(suggested);
    }
  };

  const setItem = (e, n) => {
    e.preventDefault();
    const _data = { ...inputState[idx], ...autoResp[n] };
    inputState[idx] = _data;
    setInputState([...inputState]);
    sendResponse(e, idx);
    setAutoResp(false);
  };

  const blurWait = (e) => {
    const event = { ...e };
    e.preventDefault();
    setTimeout(
      (event) => {
        setAutoResp(false);
        sendResponse(event, idx);
      },
      1000,
      event
    );
  };

  const elemChange = (e) => {
    const { value, id, name } = e.target;
    let _value = value;
    if (elem.format) {
      _value = elem.format(value);
    }
    const _data = { ...inputState[idx], [name || id]: _value };
    inputState[idx] = _data;
    setInputState([...inputState]);
    setElemState(_value);
    if (_value.length >= 3) {
      callEndpoint(_value);
    }
  };

  useEffect(() => {
    if (resetFields || updateState) {
      setElemState(inputState[idx][elem.id]);
    }
  }, [resetFields, updateState, inputState]);

  return (
    <>
      {!editing && elem.type !== "hidden" && elem.value && (
        <>
          {!hideLables && <strong>{elem.label}</strong>}
          <span>{elem.altValue || elem.value}</span>
        </>
      )}
      {editing && (
        <>
          <label htmlFor={elem.id} className={hideLables ? "hide" : ""}>
            <strong>
              {elem.label}
              {elem.subLabel && (
                <>
                  <br />
                  <span
                    dangerouslySetInnerHTML={{ __html: elem.subLabel }}
                  ></span>
                </>
              )}
            </strong>
          </label>
          <input
            type="text"
            id={elem.id}
            name={elem.id}
            value={elemState || ""}
            placeholder={elem.placeholder}
            className={`${hasError(idx, elem.id) ? "error" : ""} ${
              elem.class ? elem.class : ""
            }`}
            onChange={(e) => elemChange(e)}
            onBlur={(e) => blurWait(e)}
            autoFocus={elem.autofocus}
            required={elem.required}
            disabled={elem.disabled}
            autoComplete="off"
          />

          <div className={!autoResp ? "hidden" : "smartyResponse"}>
            {autoResp &&
              autoResp.map((item, idx) => (
                <button
                  onClick={(e) => {
                    setItem(e, idx);
                  }}
                  key={idx}
                >
                  {item.title}
                </button>
              ))}
          </div>
        </>
      )}
    </>
  );
}
