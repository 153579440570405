import { useContext } from "react";
import { FormBuilderContext } from "@context/formbuilder.context";

export default function CCexp({ hideLables, elem, idx }) {
  const {
    inputState,
    setInputState,
    editing,
    hasError,
    addRemoveError,
    sendResponse,
  } = useContext(FormBuilderContext);

  const validateExp = (e, element, group, showError = true) => {
    const { value, id } = e.target;
    let _pass = true;
    let _error = "";
    if (id === "year") {
      _pass = value.length === 4;
      _error = "Credit Card Year is Invalid";
    }
    if (id === "month") {
      _pass = value <= 12 && value >= 1;
      _error = "Credit Card Month is Invalid";
    }
    if (id === "cvv") {
      _pass = value.length >= 3;
      _error = "CVV is Invalid";
    }

    if (!_pass) {
      if (showError) {
        addRemoveError(group, element.type, _error);
      }
      sendResponse(e, group, true);
    } else {
      addRemoveError(group, element.type);
      sendResponse(e, group);
    }
  };

  const setExpChange = (e, element, group) => {
    const { value, id } = e.target;
    const _isNum = /^-?\d+\.?\d*$/.test(value);
    if (_isNum || value === "") {
      const _data = { ...inputState[group], [id]: value };
      inputState[group] = _data;
      setInputState([...inputState]);
      validateExp(e, element, group, false);
    }
  };

  return (
    <>
      {!editing && elem.value && (
        <>
          {!hideLables && <strong>{elem.label}</strong>}
          <span>{elem.altValue || elem.value}</span>
        </>
      )}
      {editing && (
        <div
          className={`${hasError(idx, elem.type) ? "error" : ""} multi-input`}
        >
          <input
            id={elem.id}
            type="text"
            maxLength="2"
            value={inputState[idx][elem.id]}
            onChange={(e) => setExpChange(e, elem, idx)}
            onBlur={(e) => validateExp(e, elem, idx)}
          />
          <span>/</span>
          <input
            id={elem.id2}
            type="text"
            maxLength="4"
            value={inputState[idx][elem.id2]}
            onChange={(e) => setExpChange(e, elem, idx)}
            onBlur={(e) => validateExp(e, elem, idx)}
          />
          <input
            id={elem.id3}
            type="text"
            maxLength="4"
            value={inputState[idx][elem.id3]}
            placeholder="CVV"
            onChange={(e) => setExpChange(e, elem, idx)}
            onBlur={(e) => validateExp(e, elem, idx)}
          />
        </div>
      )}
    </>
  );
}
