import { useEffect, useContext, useState } from "react";
import { FormBuilderContext } from "@context/formbuilder.context";

export default function Checkbox({ hideLables, elem, idx }) {
  const {
    inputState,
    updateState,
    editing,
    setInputState,
    validate,
    resetFields,
  } = useContext(FormBuilderContext);

  const [elemState, setElemState] = useState(inputState[idx][elem.id]);
  const [elementChecked, setElementChecked] = useState(elem.checked);

  const elemChange = (e, elem) => {
    const _el = { ...e };
    setTimeout(() => {
      validate(_el, elem, idx);
    }, 500);
  };

  const handleClick = (e, elem) => {
    setElementChecked((previouslyChecked) => !previouslyChecked);
    const { value, id, checked } = e.target;
    let _value = checked ? elem.checkedValue : "";
    setElemState(_value);
    const _data = { ...inputState[idx], [id]: _value };
    inputState[idx] = _data;
    setInputState([...inputState]);
  };

  useEffect(() => {
    if (resetFields || updateState) {
      setElemState(inputState[idx][elem.id]);
    }
  }, [resetFields, updateState, inputState]);

  return (
    <>
      {!editing && elem.type !== "hidden" && elem.value && (
        <>
          {!hideLables && <strong>{elem.label}</strong>}
          <span>{elem.altValue || elem.value}</span>
        </>
      )}
      {editing && (
        <>
          <label htmlFor={elem.id}>
            <div className={`radio-btn ${elementChecked && "checked"}`}>
              <i className="cat_paw"></i>
            </div>
            {elem.label && (
              <>
                <strong>{elem.label}</strong>
                {elem.subLabel && <br />}
              </>
            )}

            {!elem.label && elem.subLabel && (
              <span dangerouslySetInnerHTML={{ __html: elem.subLabel }}></span>
            )}
          </label>
          <input
            type={elem.type}
            id={elem.id}
            value={elemState}
            checked={elementChecked}
            placeholder={elem.placeholder}
            className={elem.class}
            onChange={(e) => elemChange(e, elem)}
            onClick={(e) => handleClick(e, elem)}
          />
        </>
      )}
    </>
  );
}
